<template>
  <div>
    <a-spin :spinning="loading">
      <div class="card" :class="$style.container">
        <div class="text-dark font-size-24 mb-4">
          <strong>Create your account</strong>
          <p class="font-size-12">
            Please fill in your personal information below
          </p>

          <p class="font-size-12" v-if="error" style="color: red">
            {{ error }}
          </p>
        </div>

        <a-form class="mb-4" :form="form" @submit="handleSubmit">
          <a-form-item>
            <a-input
              size="large"
              placeholder="Email Address"
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your email address',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <facility-phone-number-form-item :showLabel="false" />

          <a-form-item>
            <a-input-password
              size="large"
              type="password"
              placeholder="Password"
              v-decorator="[
                'password',
                {
                  rules: [
                    { required: true, message: 'Please input your password' },
                    {
                      pattern: /(?=^.{5,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,
                      message:
                        'Password must contain atleast a digit, an uppercase and lower case letter, 6 characters minimum and a special character',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item>
            <a-input-password
              size="large"
              type="password"
              placeholder="Confirm Password"
              v-decorator="[
                'confirm_password',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please repeat your password',
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <AddUserType :showLabel="false" />

          <a-button
            type="primary"
            size="large"
            class="text-center w-100"
            :loading="loading"
            htmlType="submit"
          >
            <strong>Sign Up</strong>
          </a-button>
        </a-form>

        <div>
          <span class="mr-1">By signing up, you agree to the</span>
          <a href="/terms" class="kit__utils__link" target="_blank"
            >Terms And Conditions</a
          >
          and
          <a href="/privacy" class="kit__utils__link" target="_blank"
            >Privacy Policy</a
          >
        </div>
      </div>

      <div class="text-center pt-2 mb-auto">
        <span class="mr-2">Already have an account?</span>
        <router-link to="/auth/login" class="kit__utils__link font-size-16"
          >Sign in</router-link
        >
      </div>
    </a-spin>
  </div>
</template>

<script>
import FacilityPhoneNumberFormItem from "@/components/facilities/FacilityPhoneNumberFormItem";
import AddUserType from "@/components/shared/register/modules/AddUserType.vue";

export default {
  name: "CuiRegister",
  components: {
    FacilityPhoneNumberFormItem,
    AddUserType,
  },
  computed: {
    loading() {
      return this.load;
    },
  },
  data: function () {
    return {
      load: false,

      error: "",
      form: this.$form.createForm(this),
    };
  },
  methods: {
    compareToFirstPassword(rule, value, callback) {
      if (value && value !== this.form.getFieldValue("password")) {
        callback("Two passwords that you enter is inconsistent!");
      } else {
        callback();
      }
    },
    coercePhone(number, prefix) {
      return {
        number,
        formatted:
          parseInt(number[0]) === 0
            ? `${prefix}${number.slice(1)}`
            : `${prefix}${number}`,
      };
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        try {
          if (!err) {
            this.register(values);
          }
        } catch (err) {
          console.log(err);
        }
      });
    },
    async register(values) {
      try {
        this.error = "";
        this.load = true;

        const formattedPhone = this.coercePhone(
          values.phones,
          values.prefixPhone
        );

        delete values.confirm_password;
        values.phone = formattedPhone.formatted;

        const response = await this.$store.dispatch("auth/AUTH_REGISTER", {
          payload: values,
        });

        if (response && response.status === "success") {
          this.$notification.success({
            message: "Succesful Registered",
            description:
              "You have successfully registered! Please check your email for an activation link",
          });
          this.$router.push("/auth/login").catch(() => {});
        } else {
          this.$notification.success({
            message: "An error occurred,please try again",
            description: "",
          });
          this.$router.push("/auth/register-entity").catch(() => {});
        }

        this.load = false;
      } catch (error) {
        const error_message =
          error.message || "An error occurred,please try again";
        this.error = error_message;

        this.$notification.error({
          message: error_message,
          description: "",
        });
      } finally {
        this.load = false;
      }
    },
  },
};
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
