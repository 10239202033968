<template>
  <a-form-item :label="showLabel ? 'User Type' : ''">
    <a-select
      size="large"
      placeholder="Please choose an account type"
      v-decorator="[
        'userType',
        {
          rules: [{ required: true, message: 'Please choose an account type' }],
        },
      ]"
    >
      <a-select-option
        v-for="(item, index) of userTypes"
        :key="index"
        :value="item.value"
      >
        {{ item.label }}
      </a-select-option>
    </a-select>
  </a-form-item>
</template>
<script>
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      userTypes: [
        {
          label: "Accommodation",
          value: "hotel_and_accommodations",
        },
        {
          label: "Sites & Attractions",
          value: "tour_sight",
        },
        {
          label: "Food & Beverages",
          value: "food_and_drinks",
        },
        {
          label: "Travel Trade",
          value: "tour_operator",
        },

        {
          label: "Transport Rentals",
          value: "transport",
        },

        {
          label: "Events",
          value: "events",
        },
      ],
    };
  },
};
</script>
